import clsx from 'clsx'
import * as styles from './HTMLContent.module.css'

interface Props
  extends Omit<
    React.ComponentProps<'div'>,
    'children' | 'dangerouslySetInnerHTML'
  > {
  html: string
  variant?:
    | 'htmlContent'
    | 'doctorHtmlContent'
    | 'textWithImageHtmlContent'
    | 'resources'
    | 'map'
    | 'newsPost'
    | 'faqAnswer'
}

export const HTMLContent = ({
  html,
  className,
  variant = 'htmlContent',
  ...props
}: Props) => {
  const htmlClassName = styles[variant]

  return (
    <div
      className={clsx(className, htmlClassName)}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  )
}
