import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'

import { PageBodySmallHeroFragment } from '../types.gql'
import { TI_LEAF_FLUID_OBJ } from '../lib/constants'
import { BoundedBoxTw } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent/HTMLContent'
import clsx from 'clsx'
import { MapToPropsMetadata } from '../components/MapToComponents'

const PageBodySmallHero = ({
  heading,
  imageFluid,
  textHTML,
  bgClassName = 'bg-white',
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  return (
    <section>
      <div className="relative h-[160px] sm:h-[225px] md:h-[275px]">
        <div className="absolute inset-0 pointer-events-none">
          <GatsbyImage
            fluid={imageFluid ?? TI_LEAF_FLUID_OBJ}
            className="absolute w-full h-full"
            imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        </div>

        {/* Curved Bottom */}
        <div
          className={clsx('absolute inset-0 pointer-events-none', bgClassName)}
          style={{ clipPath: 'ellipse(60% 15% at 50% 100%)' }}
        />

        <div className="relative flex items-center justify-center h-full">
          {heading && (
            // Used to offset the clipPath to center text
            <div className="top-[-7.5%] text-white relative">
              <h3 className="font-bold serif-24-1_5 sm:serif-30-1_5 md:serif-36-1_5">
                {heading}
              </h3>
            </div>
          )}
        </div>
      </div>

      {textHTML && (
        <BoundedBoxTw
          className={clsx(
            'pt-10 pb-12 border-b border-solid border-green-48 bg-gray-93',
            'sm:pt-11 sm:pb-16',
            'md:pt-[3.75rem] md:pb-24'
          )}
        >
          <HTMLContent
            html={textHTML}
            className="px-4 mx-auto max-w-[90ch] text-center text-gray-18"
          />
        </BoundedBoxTw>
      )}
    </section>
  )
}

export function mapDataToProps(
  data: PageBodySmallHeroFragment,
  metadata: MapToPropsMetadata
) {
  const textHTML = data.primary?.text?.html
  const bgClassName = Boolean(textHTML)
    ? 'bg-gray-93'
    : metadata?.nextContext?.bg ?? 'bg-white'

  return {
    heading: data.primary?.heading?.text,
    imageFluid: data.primary?.image?.fluid as FluidObject,
    textHTML,
    bgClassName,
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-gray-93',
  }
}

export const fragment = graphql`
  fragment PageBodySmallHero on PrismicPageBodySmallHero {
    primary {
      heading {
        text
      }
      text {
        html
      }
      image {
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodySmallHero
