import clsx from 'clsx'

export const HighlightedText = ({
  children,
  ...props
}: React.ComponentProps<'div'>): JSX.Element => {
  return (
    <div {...props}>
      {/* We need this duplicated element to properly measure and un-clip our
      text for the highlight. */}
      <div
        aria-hidden
        className={clsx(
          'text-transparent absolute pointer-events-none leading-1 font-sans',
          'text-16 font-semibold md:text-18'
        )}
      >
        {children}
        <div
          aria-hidden
          className="absolute -left-2 -right-2 bottom-0 h-[75%] bg-green-84"
        />
      </div>

      <p className="relative font-semibold sans-16-1_5 md:sans-18-1_5 text-green-43">
        {children}
      </p>
    </div>
  )
}
