import React from 'react'
import clsx from 'clsx'
import { BoundedBoxTw } from '../components/BoundedBox'
import { Link } from '../components/Link'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { PageTemplateQuery } from '../types.gql'
import { pathsForDoc, RuntimeDocument } from '../lib/pathsForDoc'

const PageBodyBreadcrumbs = ({
  paths,
  shouldNegativeMargin,
  maxWidthClassName = 'max-w-md',
}: ReturnType<typeof mapDataToProps>) => {
  return (
    <BoundedBoxTw
      as="nav"
      data-breadcrumbs
      maxWidthClassName={maxWidthClassName}
      className={clsx(
        'pt-12 isolate',
        shouldNegativeMargin && '-mb-10 sm:mb-[-3.75rem]'
      )}
      innerClassName={clsx(
        'grid grid-flow-col justify-start gap-[6px] uppercase text-gray-55',
        'tracking-widest font-semibold text-center'
      )}
    >
      {paths.map((path, idx) => {
        const isLastPath = idx === paths.length - 1
        const className = clsx(
          'sans-12-1_5 uppercase',
          !isLastPath && 'hover:underline focus:underline'
        )

        return (
          <React.Fragment key={path.href}>
            {isLastPath ? (
              <p className={className}>{path.title}</p>
            ) : (
              <Link
                href={path.href === '/' ? path.href : `/${path.href}`}
                className={className}
              >
                {path.title}
              </Link>
            )}

            {!isLastPath && <p className="sans-11-1_5">/</p>}
          </React.Fragment>
        )
      })}
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  _data: unknown,
  { rootData, slices, idx }: MapToPropsMetadata<PageTemplateQuery>
) {
  const nextSlice = slices[idx + 1]
  const pullSlices = ['PrismicPageBodyDoctors']

  return {
    paths: pathsForDoc(rootData?.prismicPage as RuntimeDocument),
    shouldNegativeMargin: pullSlices.includes(nextSlice.__typename),
    maxWidthClassName: 'max-w-md',
  }
}

export default PageBodyBreadcrumbs
