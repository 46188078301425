export interface RuntimeDocument {
  uid?: string
  data?: {
    parent?: { document?: RuntimeDocument }
    title?: {
      text?: string
    }
  }
}

interface PathDescriptor {
  href: string
  title: string
}

export function pathsForDoc(doc: RuntimeDocument): PathDescriptor[] {
  const parent = doc.data?.parent?.document
  if (!parent) return [{ href: doc.uid!, title: doc.data?.title?.text! }]

  const parentPaths = pathsForDoc(parent)
  const currentHrefs = [...parentPaths.map((p) => p.href), doc.uid!]

  return [
    ...parentPaths,
    { href: currentHrefs.join('/'), title: doc.data?.title?.text! },
  ].filter((doc) => Boolean(doc.href))
}
