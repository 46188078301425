import { graphql } from 'gatsby'

import { Button } from '../components/Button'
import { TiLeaf } from '../components/Ti-Leaf'
import { BoundedBoxTw } from '../components/BoundedBox'
import { HighlightedText } from '../components/HighlightedText'
import { Link } from '../components/Link'
import { PageBodyCallToActionFragment } from '../types.gql'
import clsx from 'clsx'
import { HTMLContent } from '../components/HTMLContent/HTMLContent'
import { MapToPropsMetadata } from '../components/MapToComponents'

const PageBodyCallToAction = ({
  buttonText,
  smallHeading,
  textHTML,
  buttonUrl,
  nextSharesBg,
}: Partial<ReturnType<typeof mapDataToProps>>) => {
  return (
    <BoundedBoxTw
      as="section"
      className={clsx(
        'border-t border-green-48 border-b bg-gray-93 relative overflow-hidden',
        'py-16 md:py-[4.5rem] lg:py-24',
        nextSharesBg && 'pb-0 md:pb-0 lg:pb-0'
      )}
    >
      <div
        className={clsx(
          'absolute pointer-events-none opacity-5',
          'w-[600px] sm:w-[1000px] md:w-[1300px] lg:w-[1600px]',
          'top-0 sm:top-[-250px] md:top-[-350px]',
          'right-[-250px] md:right-[-350px]',
          'bottom-[-50px] sm:bottom-[-250px] md:bottom-[-350px]'
        )}
      >
        <TiLeaf className="w-full h-full" />
      </div>

      <div className="relative grid items-center text-center gap-y-10 justify-items-center">
        {smallHeading && <HighlightedText>{smallHeading}</HighlightedText>}

        {textHTML && (
          <HTMLContent html={textHTML} className="mx-auto max-w-[95ch]" />
        )}

        {buttonText && (
          <Button as={Link} href={buttonUrl} variant="primary">
            {buttonText}
          </Button>
        )}
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyCallToActionFragment,
  metadata: MapToPropsMetadata
) {
  return {
    buttonText: data.primary?.button_text,
    smallHeading: data.primary?.small_heading?.text,
    textHTML: data.primary?.text?.html,
    buttonUrl: data.primary?.button_url?.url,
    nextSharesBg: metadata?.nextContext?.bg === 'bg-gray-93',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-gray-93',
  }
}

export const fragment = graphql`
  fragment PageBodyCallToAction on PrismicPageBodyCallToAction {
    primary {
      button_text
      button_url {
        url
      }
      small_heading {
        text
      }
      text {
        html
      }
    }
  }
`

export default PageBodyCallToAction
